import React, {Component} from 'react';
import $ from 'jquery';
import {Button, Popup} from "devextreme-react";
import Form, {SimpleItem, PatternRule, RequiredRule} from 'devextreme-react/form';
import ScrollView from 'devextreme-react/scroll-view';
import LDH from '../helpers/LeopardDataHelper';
import LeopardDataHelper from '../helpers/LeopardDataHelper';
import LRH from '../helpers/LeopardReactHelper';
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";

class LeopardFormEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputFields: [],
            customFields: [],
            formData: null,
            popupVisible: false,
            dataSourceId: "",
            dataSources: {}
        };
        this.popupTitle = "";
        this.formDataToSubmit = {};
        this.callerInfo = null;
        this.uiObjectInstances = [];
        this.instanceInitialized = false;
        this.refreshGridViewOnPopupClosed = false;
        this.disposingAllInstances = false;
        this.gridViewDefinition = [];
        this.parentData = null;
    }

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    popupOnHide = () => {
        let that = this;
        window.Global_PopupCustomColumnData = null;
        window.Global_PopupTempParentData = null;

        this.uiObjectInstances = [];
        this.callerInfo = null;
        this.formDataToSubmit = {};

        this.setState({
            inputFields: [], formData: null, customFields: [],
            dataSourceId: ""
        }, function () {
            if (that.refreshGridViewOnPopupClosed) {
                that.props.popupOnClosed();
            }
        });
    };

    popupOnShown = () => {
        let that = this;
        if (this.instanceInitialized === false) {
            this.instanceInitialized = true;
            let instances = [];
            let instanceKeys = Object.keys(this.uiObjectInstances);
            for (let i = 0; i < instanceKeys.length; i++) {
                let key = instanceKeys[i];
                instances.push(this.uiObjectInstances[key]);
            }
            this.props.addDisposablePopupInstances(instances);
        }
        let dataSourcesClone = LDH.DeepClone(that.state.dataSources);
        let definition = this.gridViewDefinition;

        for (let j = 0; j < definition.columnDefinition.length; j++) {
            let inputField = definition.columnDefinition[j];

            if (!LDH.IsObjectNull(inputField.refDataType) && !LDH.IsObjectNull(inputField.refDataOwnerId) &&
                !LDH.IsObjectNull(inputField.refDataFileName) && !LDH.IsObjectNull(inputField.refDataFileVersion) &&
                !LDH.IsObjectNull(inputField.refDataProperty)) {
                let ownerId = "";
                if (inputField.refDataOwnerId.toLowerCase() === "userid") {
                    ownerId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile).toUpperCase();
                } else {
                    ownerId = LeopardDataHelper.GetOrganizationIdFromUserProfile(window.userProfile).toUpperCase();
                }
                $(".form-editor-loading-icon .loading-icon").show();
                that.uiObjectInstances["buttonApply"].instance.option("disabled", true);
                that.uiObjectInstances["buttonCancel"].instance.option("disabled", true);
                that.uiObjectInstances["buttonClose"].instance.option("disabled", true);

                LeopardAjaxHelper.RetrieveDocumentFromS3(ownerId, inputField.refDataFileName, inputField.refDataType, function (documentData) {
                    let jsonData = JSON.parse(documentData);
                    jsonData = LDH.ConvertJsonDataNullValuesOnly(jsonData, false);

                    let fieldName = inputField.columnName;
                    if (LDH.IsObjectNull(fieldName) || LDH.IsValueEmpty(fieldName)) fieldName = inputField.fieldName;
                    dataSourcesClone["selectbox_" + fieldName + "_" + inputField.refDataProperty] = jsonData[inputField.refDataProperty];
                    that.setState({dataSources: dataSourcesClone}, function () {
                        $(".form-editor-loading-icon .loading-icon").hide();
                        that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                    });
                }, function (error, sessionTimeout) {
                });
            }
        }

        for (let v = 0; v < definition.customColumnConfiguration.customColumns.length; v++) {
            let customColumns = definition.customColumnConfiguration.customColumns[v];
            let customInputFields = [];

            if (!LDH.IsObjectNull(customColumns.customFields) &&
                !LDH.IsValueEmpty(customColumns.customFields)) {
                customInputFields = JSON.parse(customColumns.customFields);
            }

            for (let i = 0; i < customInputFields.length; i++) {
                if (typeof customInputFields[i].dataSource === "undefined" ||
                    customInputFields[i].dataSource !== "reference-data") {
                    continue;
                }
                let inputField = customInputFields[i];

                let ownerId = "";
                if (inputField.refDataOwnerId.toLowerCase() === "userid") {
                    ownerId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile).toUpperCase();
                } else {
                    ownerId = LeopardDataHelper.GetOrganizationIdFromUserProfile(window.userProfile).toUpperCase();
                }
                $(".form-editor-loading-icon .loading-icon").show();
                that.uiObjectInstances["buttonApply"].instance.option("disabled", true);
                that.uiObjectInstances["buttonCancel"].instance.option("disabled", true);
                that.uiObjectInstances["buttonClose"].instance.option("disabled", true);

                LeopardAjaxHelper.RetrieveDocumentFromS3(ownerId, inputField.refDataFileName, inputField.refDataType, function (documentData) {
                    let jsonData = JSON.parse(documentData);
                    jsonData = LDH.ConvertJsonDataNullValuesOnly(jsonData, false);
                    dataSourcesClone["selectbox_" + inputField.fieldName + "_" + inputField.refDataProperty] = jsonData[inputField.refDataProperty];
                    that.setState({dataSources: dataSourcesClone}, function () {
                        $(".form-editor-loading-icon .loading-icon").hide();
                        that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                    });
                }, function (error, sessionTimeout) {
                });
            }

            for (let i = 0; i < customInputFields.length; i++) {
                let inputField = customInputFields[i];
                if (LDH.IsObjectNull(inputField.query_odata) || LDH.IsValueEmpty(inputField.query_odata)) {
                    continue;
                }

                $(".form-editor-loading-icon .loading-icon").show();
                that.uiObjectInstances["buttonApply"].instance.option("disabled", true);
                that.uiObjectInstances["buttonCancel"].instance.option("disabled", true);
                that.uiObjectInstances["buttonClose"].instance.option("disabled", true);

                LeopardAjaxHelper.SimpleHttpGetRequest(inputField.query_odata, function (response) {
                    if (LDH.IsObjectNull(response) || !Array.isArray(response.value)) return;
                    let jsonData = response.value;
                    let dataForDropdown = [];

                    for (let i = 0; i < jsonData.length; i++) {
                        let queryvalue = LDH.ConvertArrayMacroToString(inputField.query_value, jsonData[i], null);
                        let querytext = LDH.ConvertArrayMacroToString(inputField.query_text, jsonData[i], null);
                        dataForDropdown.push({value: queryvalue, text: querytext});
                    }
                    dataSourcesClone["selectbox_" + inputField.fieldName + "_OdataDS"] = dataForDropdown;
                    that.setState({dataSources: dataSourcesClone}, function () {
                        $(".form-editor-loading-icon .loading-icon").hide();
                        that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                    });
                }, function (error, sessionTimeout) {
                });
            }
        }
    };

    popupOnShowing = () => {
        let that = this;
        let columns = LDH.DeepClone(window.Global_PopupCustomColumnData.limitedColumns);
        let definition = LDH.DeepClone(window.Global_PopupCustomColumnData.definition);
        this.parentData = LDH.DeepClone(window.Global_PopupTempParentData);
        this.gridViewDefinition = definition;
        let dataSources = {};

        let callerInfo = window.Global_PopupCustomColumnData.data;
        this.popupTitle = callerInfo.linkText;
        this.callerInfo = callerInfo;
        this.refreshGridViewOnPopupClosed = false;

        let data = null;
        if (callerInfo.columnType === "modify-row" || callerInfo.columnType === "reset-password") {
            data = window.Global_PopupCustomColumnData.e.row.data;
        }
        if (callerInfo.columnType === "action-item") {
            data = window.Global_PopupCustomColumnData.rowData;
        }

        if (callerInfo.columnType === "add-row") {
            this.popupTitle = "Add Row";
        }

        if (LDH.IsObjectNull(columns) || columns.length === 0) {
            return;
        }

        let enableCancelButton = LDH.IsObjectNull(callerInfo.enableCancelButton) ? true : callerInfo.enableCancelButton;
        let enableApplyButton = LDH.IsObjectNull(callerInfo.enableApplyButton) ? true : callerInfo.enableApplyButton;
        let enableCloseButton = LDH.IsObjectNull(callerInfo.enableCloseButton) ? false : callerInfo.enableCloseButton;

        that.uiObjectInstances["buttonCancel"].instance.option("visible", enableCancelButton);
        that.uiObjectInstances["buttonApply"].instance.option("visible", enableApplyButton);
        that.uiObjectInstances["buttonClose"].instance.option("visible", enableCloseButton);

        this.getDataFromEventSync(data, callerInfo, function (data) {
            let dataSourceId = "";
            let inputFields = [];
            let customInputFields = [];
            let indexStartFrom = 1000;

            for (let i = 0; i < columns.length; i++) {
                let fieldName = columns[i];
                let inputField = {
                    fieldName,
                    fieldType: "string",
                    isDisabled: false,
                    isHidden: false,
                    isInvisible: false,
                    caption: fieldName,
                    visibleIndex: indexStartFrom,
                    validationRules: [],
                    allowAutoComplete: false,
                    autoCompleteDataSource: null,
                    autoCompleteDataSourceKeyName: null,
                    autoCompleteOperation: "startswith",
                    refDataType: "",
                    refDataOwnerId: "",
                    refDataFileName: "",
                    refDataFileVersion: null,
                    refDataProperty: "",
                    visibleProperty: true,
                    defaultValue: ""
                };

                for (let j = 0; j < definition.columnDefinition.length; j++) {
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase()) {
                        if (LDH.IsObjectNull(definition.columnDefinition[j].visibleIndex) ||
                            LDH.IsValueEmpty(definition.columnDefinition[j].visibleIndex)) {
                            inputField.visibleIndex = indexStartFrom;
                        } else {
                            inputField.visibleIndex = definition.columnDefinition[j].visibleIndex;
                        }
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].columnType) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].columnType)) {
                        inputField.fieldType = definition.columnDefinition[j].columnType;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].allowAutoComplete) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].allowAutoComplete)) {
                        inputField.allowAutoComplete = definition.columnDefinition[j].allowAutoComplete;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].autoCompleteDataSource) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].autoCompleteDataSource)) {
                        inputField.autoCompleteDataSource = definition.columnDefinition[j].autoCompleteDataSource;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].columnDefaultValue) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].columnDefaultValue)) {
                        inputField.defaultValue = definition.columnDefinition[j].columnDefaultValue;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].autoCompleteDataSourceKeyName) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].autoCompleteDataSourceKeyName)) {
                        inputField.autoCompleteDataSourceKeyName = definition.columnDefinition[j].autoCompleteDataSourceKeyName;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].autoCompleteOperation) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].autoCompleteOperation)) {
                        inputField.autoCompleteOperation = definition.columnDefinition[j].autoCompleteOperation;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].refDataType) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].refDataType)) {
                        inputField.refDataType = definition.columnDefinition[j].refDataType;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].refDataOwnerId) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].refDataOwnerId)) {
                        inputField.refDataOwnerId = definition.columnDefinition[j].refDataOwnerId;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].refDataFileName) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].refDataFileName)) {
                        inputField.refDataFileName = definition.columnDefinition[j].refDataFileName;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].refDataFileVersion) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].refDataFileVersion)) {
                        inputField.refDataFileVersion = definition.columnDefinition[j].refDataFileVersion;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].refDataProperty) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].refDataProperty)) {
                        inputField.refDataProperty = definition.columnDefinition[j].refDataProperty;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].columnCustomHeader) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].columnCustomHeader)) {
                        inputField.caption = definition.columnDefinition[j].columnCustomHeader;
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() && callerInfo.columnType === "add-row" &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].columnDefaultValue) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].columnDefaultValue)) {
                        if (LDH.IsObjectNull(data)) data = {};
                        data[fieldName] = LDH.FilterMacro(definition.columnDefinition[j].columnDefaultValue);
                        that.formDataToSubmit[fieldName] = data[fieldName];
                    }
                    if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                        fieldName.toString().toLowerCase() &&
                        !LDH.IsObjectNull(definition.columnDefinition[j].validationRules) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].validationRules)) {
                        inputField.validationRules = definition.columnDefinition[j].validationRules;
                    }
                    if (!LDH.IsObjectNull(inputField.refDataType) && !LDH.IsObjectNull(inputField.refDataOwnerId) &&
                        !LDH.IsObjectNull(inputField.refDataFileName) && !LDH.IsObjectNull(inputField.refDataFileVersion) &&
                        !LDH.IsObjectNull(inputField.refDataProperty)) {
                        dataSources["selectbox_" + inputField.fieldName + "_" + inputField.refDataProperty] = [];
                    }
                }

                let customColumns = definition.customColumnConfiguration.customColumns;
                for (let v = 0; v < customColumns.length; v++) {
                    if (customColumns[v].columnType === callerInfo.columnType &&
                        customColumns[v].id === callerInfo.id) {
                        let disabledFields = customColumns[v].disabledFields;
                        if (!LDH.IsObjectNull(disabledFields) && disabledFields.length > 0) {
                            for (let b = 0; b < disabledFields.length; b++) {
                                if (disabledFields[b] === fieldName) {
                                    inputField.isDisabled = true;
                                }
                            }
                        }

                        let invisibleFields = customColumns[v].invisibleFields;
                        if (!LDH.IsObjectNull(invisibleFields) && invisibleFields.length > 0) {
                            for (let c = 0; c < invisibleFields.length; c++) {
                                if (invisibleFields[c] === fieldName) {
                                    inputField.isInvisible = true;
                                }
                            }
                        }

                        let hiddenFields = customColumns[v].hiddenFields;
                        if (!LDH.IsObjectNull(hiddenFields) && hiddenFields.length > 0) {
                            for (let n = 0; n < hiddenFields.length; n++) {
                                if (hiddenFields[n] === fieldName) {
                                    inputField.isHidden = true;
                                }
                            }
                        }

                        if (!LDH.IsObjectNull(customColumns[v].customFields) &&
                            !LDH.IsValueEmpty(customColumns[v].customFields)) {
                            customInputFields = JSON.parse(customColumns[v].customFields);
                        }

                        if (!LDH.IsObjectNull(customColumns[v].customFieldsLogic) &&
                            !LDH.IsValueEmpty(customColumns[v].customFieldsLogic)) {
                            let javascript = customColumns[v].customFieldsLogic;
                            let dataName = "data";
                            let dataValue = customInputFields;
                            customInputFields = LDH.EvaluateJavaScriptForDataShaping(javascript,
                                dataName, dataValue, "");
                        }

                        if (!LDH.IsObjectNull(customColumns[v].dataSourceId) &&
                            !LDH.IsValueEmpty(customColumns[v].dataSourceId)) {
                            dataSourceId = customColumns[v].dataSourceId;
                        }

                        for (let i = 0; i < customInputFields.length; i++) {
                            if (typeof customInputFields[i].dataSource === "undefined" ||
                                customInputFields[i].dataSource !== "reference-data") {
                                continue;
                            }
                            let inputField = customInputFields[i];
                            dataSources["selectbox_" + inputField.fieldName + "_" + inputField.refDataProperty] = [];
                        }
                    }
                }
                inputFields.push(inputField);
                indexStartFrom++;
            }

            inputFields.sort(function (a, b) {
                let orderA = LDH.IsValueEmpty(a.visibleIndex) ? 0 :
                    parseInt(a.visibleIndex);
                let orderB = LDH.IsValueEmpty(b.visibleIndex) ? 0 :
                    parseInt(b.visibleIndex);
                if (orderA < orderB) return -1;
                if (orderA > orderB) return 1;
                return 0;
            });

            let popupLogic = that.gridViewDefinition.clientSideCustomPopupLogic;
            if (!LDH.IsObjectNull(popupLogic) && !LDH.IsValueEmpty(popupLogic)) {
                let dataName = "data";
                let dataValue2 = {
                    inputFields: inputFields,
                    customFields: customInputFields
                };
                var result = LDH.EvaluateJavaScriptForDataShaping(popupLogic,
                    dataName, dataValue2, "");

                inputFields = result.inputFields;
                customInputFields = result.customFields;
            }

            for (let v = 0; v < inputFields.length; v++) {
                inputFields[v].visibleProperty = true;
            }

            for (let v = 0; v < customInputFields.length; v++) {
                customInputFields[v].visibleProperty = true;
            }

            for (let v = 0; v < inputFields.length; v++) {
                inputFields = that.setDefaultControlVisibility(inputFields[v],
                    inputFields, customInputFields, data).inputFields;
            }

            for (let v = 0; v < customInputFields.length; v++) {
                customInputFields = that.setDefaultControlVisibility(customInputFields[v],
                    inputFields, customInputFields, data).customFields;
            }

            that.setState({
                inputFields,
                customFields: customInputFields,
                formData: data,
                dataSourceId,
                dataSources: dataSources
            });
        });
    };

    getDataFromEventSync = (data, callerInfo, callback) => {
        if (!LDH.IsObjectNull(callerInfo.eventSyncRequestClone)) {
            LeopardAjaxHelper.SendRequestByEventSync(function (dataFromApi) {
                if (!LDH.IsObjectNull(dataFromApi) && !LDH.IsObjectNull(dataFromApi.message)) {
                    dataFromApi = dataFromApi.message;
                }
                if (!LDH.IsObjectNull(dataFromApi) && !LDH.IsObjectNull(dataFromApi.body)) {
                    dataFromApi = dataFromApi.body;
                }
                if (!LDH.IsObjectNull(dataFromApi) && !LDH.IsObjectNull(dataFromApi.data)) {
                    dataFromApi = dataFromApi.data;
                }
                callback(dataFromApi);
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to send a message to the recipient.", "error", 5000);
                }
            }, callerInfo.eventSyncRequestClone, "not-required");
        } else {
            callback(data);
        }
    };

    generateValidationRules = (data, prefix, fieldName, itemJSON) => {
        if (itemJSON.isDisabled === true) return null;

        let jsonData = data;
        if (prefix === "predefined") jsonData = JSON.parse(data);

        return jsonData.map(function (item, i) {
            let key = prefix + "_" + fieldName + "_patternRule_" + i;

            if (item.regex === "[required]") {
                return <RequiredRule key={key} message={item.error} trim={true}/>
            }
            return <PatternRule key={key} message={item.error} pattern={item.regex}/>
        });
    };

    inputFieldValueOnChanged = (data) => {
        let that = this;
        this.formDataToSubmit[data.fieldName] = data.fieldValue;
        let className = ".dx-field-item-label .dx-field-item-label-content";
        let $parent = $(data.e.element).closest(".dx-item-content");
        if ($(".leopard-textchanged-indicator", $parent).length === 0) {
            $(className, $parent).append(
                "<i class='leopard-textchanged-indicator far fa-check-circle'></i>"
            );
        }

        let customFieldsCloned = LDH.DeepClone(that.state.customFields);
        let inputFieldsCloned = LDH.DeepClone(that.state.inputFields);

        if (typeof that.callerInfo.fieldVisibilityLogic !== "undefined" &&
            !LDH.IsObjectNull(that.callerInfo.fieldVisibilityLogic)) {
            let visibilityJson = JSON.parse(that.callerInfo.fieldVisibilityLogic);
            let fieldCustomKey = "";
            if (typeof data.itemObj.fieldCustomKey !== "undefined" && !LDH.IsValueEmpty(data.itemObj.fieldCustomKey)) {
                fieldCustomKey = data.itemObj.fieldCustomKey;
            }
            let combinedKey = data.fieldName + fieldCustomKey;
            let itemByKey = visibilityJson.find(i => i.fieldName + (LDH.IsObjectNull(i.fieldCustomKey) ? "" : i.fieldCustomKey) === combinedKey);
            if (LDH.IsObjectNull(itemByKey)) return;

            for (let j = 0; j < itemByKey.selectionLogic.length; j++) {
                if (!LDH.IsObjectNull(itemByKey.selectionLogic[j].fieldValue) && !LDH.IsValueEmpty(itemByKey.selectionLogic[j].fieldValue)) {
                    itemByKey.selectionLogic[j].fieldValue = LDH.FilterMacro(itemByKey.selectionLogic[j].fieldValue);
                    itemByKey.selectionLogic[j].fieldValue = LDH.ConvertArrayMacroToString(itemByKey.selectionLogic[j].fieldValue, that.state.formData);
                }
                if (!LDH.IsObjectNull(data.fieldValue) && !LDH.IsValueEmpty(data.fieldValue)) {
                    data.fieldValue = LDH.FilterMacro(data.fieldValue);
                    data.fieldValue = LDH.ConvertArrayMacroToString(data.fieldValue, that.state.formData);
                }
                if (itemByKey.selectionLogic[j].fieldValue !== data.fieldValue) {
                    continue;
                }
                for (let k = 0; k < itemByKey.selectionLogic[j].visible.length; k++) {
                    let customFieldByKey = customFieldsCloned.find(i => "customField_" + i.fieldName +
                        (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].visible[k].fieldName);
                    let inputFieldByKey = inputFieldsCloned.find(i => "inputField_" + i.fieldName +
                        (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].visible[k].fieldName);

                    if (!LDH.IsObjectNull(customFieldByKey)) customFieldByKey.visibleProperty = true;
                    if (!LDH.IsObjectNull(inputFieldByKey)) inputFieldByKey.visibleProperty = true;
                }
                for (let k = 0; k < itemByKey.selectionLogic[j].hidden.length; k++) {
                    let customFieldByKey = customFieldsCloned.find(i => "customField_" + i.fieldName +
                        (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].hidden[k].fieldName);
                    let inputFieldByKey = inputFieldsCloned.find(i => "inputField_" + i.fieldName +
                        (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].hidden[k].fieldName);

                    if (!LDH.IsObjectNull(customFieldByKey)) {
                        customFieldByKey.visibleProperty = false;
                        that.formDataToSubmit[customFieldByKey.fieldName] = "";
                    }
                    if (!LDH.IsObjectNull(inputFieldByKey)) {
                        inputFieldByKey.visibleProperty = false;
                        that.formDataToSubmit[inputFieldByKey.fieldName] = "";
                    }
                }
                for (let k = 0; k < itemByKey.selectionLogic[j].disabled.length; k++) {
                    let customFieldByKey = customFieldsCloned.find(i => "customField_" + i.fieldName +
                        (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].disabled[k].fieldName);
                    let inputFieldByKey = inputFieldsCloned.find(i => "inputField_" + i.fieldName +
                        (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].disabled[k].fieldName);

                    if (!LDH.IsObjectNull(customFieldByKey)) {
                        customFieldByKey.visibleProperty = false;
                        that.formDataToSubmit[customFieldByKey.fieldName] = "";
                    }
                    if (!LDH.IsObjectNull(inputFieldByKey)) {
                        inputFieldByKey.visibleProperty = false;
                        that.formDataToSubmit[inputFieldByKey.fieldName] = "";
                    }
                }
            }
            that.setState({customFields: customFieldsCloned, inputFields: inputFieldsCloned});
        }
    };

    setDefaultControlVisibility = (currentControl, inputFields, customFields, formData) => {
        let that = this;
        let customFieldsCloned = LDH.DeepClone(customFields);
        let inputFieldsCloned = LDH.DeepClone(inputFields);

        if (typeof that.callerInfo.fieldVisibilityLogic !== "undefined" &&
            !LDH.IsObjectNull(that.callerInfo.fieldVisibilityLogic)) {
            let visibilityJson = JSON.parse(that.callerInfo.fieldVisibilityLogic);
            for (let i = 0; i < visibilityJson.length; i++) {
                let itemByKey = visibilityJson[i];

                let fieldCustomKey = "";
                if (typeof itemByKey.fieldCustomKey !== "undefined" && !LDH.IsValueEmpty(itemByKey.fieldCustomKey)) {
                    fieldCustomKey = itemByKey.fieldCustomKey;
                }
                let currentFieldCustomKey = "";
                if (typeof currentControl.fieldCustomKey !== "undefined" && !LDH.IsValueEmpty(currentControl.fieldCustomKey)) {
                    currentFieldCustomKey = currentControl.fieldCustomKey;
                }
                if (itemByKey.fieldName !== currentControl.fieldName || fieldCustomKey !== currentFieldCustomKey) {
                    continue;
                }
                for (let j = 0; j < itemByKey.selectionLogic.length; j++) {
                    if (!LDH.IsObjectNull(itemByKey.selectionLogic[j].fieldValue) && !LDH.IsValueEmpty(itemByKey.selectionLogic[j].fieldValue)) {
                        itemByKey.selectionLogic[j].fieldValue = LDH.FilterMacro(itemByKey.selectionLogic[j].fieldValue);
                        itemByKey.selectionLogic[j].fieldValue = LDH.ConvertArrayMacroToString(itemByKey.selectionLogic[j].fieldValue, formData);
                    }
                    if (!LDH.IsObjectNull(currentControl.defaultValue) && !LDH.IsValueEmpty(currentControl.defaultValue)) {
                        currentControl.defaultValue = LDH.FilterMacro(currentControl.defaultValue);
                        currentControl.defaultValue = LDH.ConvertArrayMacroToString(currentControl.defaultValue, formData);
                    }
                    if (itemByKey.selectionLogic[j].fieldValue !== currentControl.defaultValue) {
                        continue;
                    }

                    for (let k = 0; k < itemByKey.selectionLogic[j].visible.length; k++) {
                        let customFieldByKey = customFieldsCloned.find(i => "customField_" + i.fieldName +
                            (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].visible[k].fieldName);
                        let inputFieldByKey = inputFieldsCloned.find(i => "inputField_" + i.fieldName +
                            (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].visible[k].fieldName);

                        if (!LDH.IsObjectNull(customFieldByKey)) customFieldByKey.visibleProperty = true;
                        if (!LDH.IsObjectNull(inputFieldByKey)) inputFieldByKey.visibleProperty = true;
                    }
                    for (let k = 0; k < itemByKey.selectionLogic[j].hidden.length; k++) {
                        let customFieldByKey = customFieldsCloned.find(i => "customField_" + i.fieldName +
                            (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].hidden[k].fieldName);
                        let inputFieldByKey = inputFieldsCloned.find(i => "inputField_" + i.fieldName +
                            (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].hidden[k].fieldName);

                        if (!LDH.IsObjectNull(customFieldByKey)) customFieldByKey.visibleProperty = false;
                        if (!LDH.IsObjectNull(inputFieldByKey)) inputFieldByKey.visibleProperty = false;
                    }
                    for (let k = 0; k < itemByKey.selectionLogic[j].disabled.length; k++) {
                        let customFieldByKey = customFieldsCloned.find(i => "customField_" + i.fieldName +
                            (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].disabled[k].fieldName);
                        let inputFieldByKey = inputFieldsCloned.find(i => "inputField_" + i.fieldName +
                            (LDH.IsObjectNull(i.fieldCustomKey) ? "" : "_" + i.fieldCustomKey) === itemByKey.selectionLogic[j].disabled[k].fieldName);

                        if (!LDH.IsObjectNull(customFieldByKey)) customFieldByKey.visibleProperty = false;
                        if (!LDH.IsObjectNull(inputFieldByKey)) inputFieldByKey.visibleProperty = false;
                    }
                }
            }
        }
        return {customFields: customFieldsCloned, inputFields: inputFieldsCloned};
    };

    cancelButtonOnClick = () => {
        let instance = window.Global_PopupTempObjectInstance;
        if (!LDH.IsObjectNull(instance)) instance.hide();
    };

    applyButtonOnClick = (e) => {
        let that = this;

        let validator = e.validationGroup.validate();
        if (validator.isValid === false) {
            LRH.ShowToast("Validation failed. Please correct the errors before proceeding.", "error", 5000);
            return;
        }
        let columnType = this.callerInfo.columnType;
        if (columnType === "modify-row" && (LDH.IsObjectNull(this.state.dataSourceId) ||
            LDH.IsValueEmpty(this.state.dataSourceId) ||
            LDH.IsObjectNull(this.state.formData[this.state.dataSourceId]) ||
            LDH.IsValueEmpty(this.state.formData[this.state.dataSourceId]))) {
            return;
        }
        if (columnType === "action-item" && (LDH.IsObjectNull(this.state.dataSourceId) ||
            LDH.IsValueEmpty(this.state.dataSourceId) ||
            LDH.IsObjectNull(this.state.formData[this.state.dataSourceId]) ||
            LDH.IsValueEmpty(this.state.formData[this.state.dataSourceId]))) {
            return;
        }

        if (columnType === "modify-row" || columnType === "action-item") {
            let dataSourceValue = this.state.formData[this.state.dataSourceId];
            this.formDataToSubmit[this.state.dataSourceId] = dataSourceValue;

            $(".form-editor-loading-icon .loading-icon").show();
            that.uiObjectInstances["buttonApply"].instance.option("disabled", true);
            that.uiObjectInstances["buttonCancel"].instance.option("disabled", true);
            that.uiObjectInstances["buttonClose"].instance.option("disabled", true);

            let clonedFormData = LDH.DeepClone(this.convertDataSourceWithCaseSensitive(
                this.gridViewDefinition, this.formDataToSubmit));

            let valueToSubmit = dataSourceValue;
            if (!LDH.IsObjectNull(valueToSubmit._value) && !LDH.IsValueEmpty(valueToSubmit._value)) {
                valueToSubmit = valueToSubmit._value;
            }
            let isCustomUrl = (columnType === "action-item");
            if (!LDH.IsObjectNull(that.callerInfo.apiGatewayPath) &&
                (that.callerInfo.apiGatewayPath.toLowerCase() === "/event/sync" ||
                    that.callerInfo.apiGatewayPath.toLowerCase() === "/event")) {

                let postTemplate = "";
                if (!LDH.IsObjectNull(that.callerInfo.jsonDataForHttpRequest) &&
                    !LDH.IsValueEmpty(that.callerInfo.jsonDataForHttpRequest)) {
                    postTemplate = that.callerInfo.jsonDataForHttpRequest;
                    postTemplate = LDH.FilterMacro(postTemplate);
                    postTemplate = LDH.ConvertArrayMacroToString(postTemplate, that.state.formData);

                    let dataName = "data";
                    let dataValue = "";
                    postTemplate = LDH.EvaluateJavaScriptForDataShaping(postTemplate, dataName,
                        dataValue, that.callerInfo.id);
                }

                LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                    $(".leopard-textchanged-indicator").remove();
                    $(".form-editor-loading-icon .loading-icon").hide();
                    that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                    that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                    that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                    that.refreshGridViewOnPopupClosed = true;
                    that.cancelButtonOnClick();
                    LRH.ShowToast("Your request has been successfully sent.", "success", 5000);
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to process your request. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    $(".form-editor-loading-icon .loading-icon").hide();
                    that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                    that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                    that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                }, postTemplate, "not-required", that.callerInfo.apiGatewayPath);
            } else {
                LeopardAjaxHelper.GridViewCRUD_UpdateData(this.callerInfo.apiGatewayPath,
                    clonedFormData, valueToSubmit, function () {
                        $(".leopard-textchanged-indicator").remove();
                        $(".form-editor-loading-icon .loading-icon").hide();
                        that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                        that.refreshGridViewOnPopupClosed = true;
                        that.cancelButtonOnClick();
                        LRH.ShowToast("The data has been successfully updated.", "success", 5000);
                    }, function (ex) {
                        $(".form-editor-loading-icon .loading-icon").hide();
                        that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                        LRH.ShowToast("Failed to update the data in the table. " +
                            JSON.stringify(ex.message), "error", 5000);
                    }, isCustomUrl);
            }
        }

        if (columnType === "add-row") {
            $(".form-editor-loading-icon .loading-icon").show();
            that.uiObjectInstances["buttonApply"].instance.option("disabled", true);
            that.uiObjectInstances["buttonCancel"].instance.option("disabled", true);
            that.uiObjectInstances["buttonClose"].instance.option("disabled", true);

            let clonedFormData = LDH.DeepClone(this.convertDataSourceWithCaseSensitive(
                this.gridViewDefinition, this.formDataToSubmit));

            if (!LDH.IsObjectNull(this.callerInfo.customColumnTemplateDirectory) &&
                !LDH.IsValueEmpty(this.callerInfo.customColumnTemplateDirectory) &&
                !LDH.IsObjectNull(this.callerInfo.customColumnTemplateFilename) &&
                !LDH.IsValueEmpty(this.callerInfo.customColumnTemplateFilename)) {
                let profile = window.userProfile;
                LeopardAjaxHelper.RetrieveDocumentFromS3(profile.Parent, this.callerInfo.customColumnTemplateFilename,
                    this.callerInfo.customColumnTemplateDirectory, function (documentData) {
                        documentData = LDH.FilterMacro(documentData);

                        if (!LDH.IsObjectNull(that.parentData)) {
                            let parentKeys = Object.keys(that.parentData);

                            for (let k = 0; k < parentKeys.length; k++) {
                                let parentValue = that.parentData[parentKeys[k]];
                                documentData = LDH.ReplaceAll(documentData, "{Parent_" + parentKeys[k] + "}", parentValue);
                            }
                        }
                        let keys = Object.keys(clonedFormData);
                        for (let v = 0; v < keys.length; v++) {
                            let formValue = clonedFormData[keys[v]];
                            documentData = LDH.ReplaceAll(documentData, "{" + keys[v] + "}", formValue);
                        }

                        documentData = JSON.parse(documentData);

                        if (!LDH.IsObjectNull(that.callerInfo.dataRequestLogic) &&
                            !LDH.IsValueEmpty(that.callerInfo.dataRequestLogic)) {
                            let javascript = that.callerInfo.dataRequestLogic;
                            let dataName = "data";
                            let dataValue = documentData;
                            documentData = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                dataValue, that.callerInfo.id, null);
                        }
                        LeopardAjaxHelper.GridViewCRUD_InsertData(that.callerInfo.apiGatewayPath,
                            documentData, function () {
                                $(".leopard-textchanged-indicator").remove();
                                $(".form-editor-loading-icon .loading-icon").hide();
                                that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                                that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                                that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                                that.refreshGridViewOnPopupClosed = true;
                                that.cancelButtonOnClick();
                                LRH.ShowToast("The data has been successfully added.", "success", 5000);
                            }, function (ex) {
                                $(".form-editor-loading-icon .loading-icon").hide();
                                that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                                that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                                that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                                LRH.ShowToast("Failed to add the data to the table. " +
                                    JSON.stringify(ex.message), "error", 5000);
                            }, false);
                    });
            } else if (!LDH.IsObjectNull(that.callerInfo.jsonDataForHttpRequest) &&
                !LDH.IsValueEmpty(that.callerInfo.jsonDataForHttpRequest) &&
                (that.callerInfo.apiGatewayPath.toLowerCase() === "/event/sync" ||
                    that.callerInfo.apiGatewayPath.toLowerCase() === "/event")) {
                let postTemplate = that.callerInfo.jsonDataForHttpRequest;
                postTemplate = LDH.FilterMacro(postTemplate);
                postTemplate = LDH.ConvertArrayMacroToString(postTemplate, clonedFormData);

                let dataName = "data";
                let dataValue = clonedFormData;
                postTemplate = LDH.EvaluateJavaScriptForDataShaping(postTemplate, dataName, dataValue, that.callerInfo.id);
                if (!LDH.IsObjectNull(that.callerInfo.sendRequestAsSingleObject) && that.callerInfo.sendRequestAsSingleObject &&
                    Array.isArray(postTemplate.data)) {
                    postTemplate.data = postTemplate.data[0];
                }
                LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                    $(".leopard-textchanged-indicator").remove();
                    $(".form-editor-loading-icon .loading-icon").hide();
                    that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                    that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                    that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                    that.refreshGridViewOnPopupClosed = true;
                    that.cancelButtonOnClick();
                    LRH.ShowToast("Your request has been successfully sent.", "success", 5000);
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to process your request. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    $(".form-editor-loading-icon .loading-icon").hide();
                    that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                    that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                    that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                }, postTemplate, "not-required", that.callerInfo.apiGatewayPath);
            } else {
                LeopardAjaxHelper.GridViewCRUD_InsertData(that.callerInfo.apiGatewayPath,
                    clonedFormData, function () {
                        $(".leopard-textchanged-indicator").remove();
                        $(".form-editor-loading-icon .loading-icon").hide();
                        that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                        that.refreshGridViewOnPopupClosed = true;
                        that.cancelButtonOnClick();
                        LRH.ShowToast("The data has been successfully added.", "success", 5000);
                    }, function (ex) {
                        $(".form-editor-loading-icon .loading-icon").hide();
                        that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                        that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                        LRH.ShowToast("Failed to add the data to the table. " +
                            JSON.stringify(ex.message), "error", 5000);
                    });
            }
        }

        if (columnType === "reset-password") {
            let dataSourceValue = this.state.formData[this.state.dataSourceId];
            this.formDataToSubmit[this.state.dataSourceId] = dataSourceValue;

            $(".form-editor-loading-icon .loading-icon").show();
            that.uiObjectInstances["buttonApply"].instance.option("disabled", true);
            that.uiObjectInstances["buttonCancel"].instance.option("disabled", true);
            that.uiObjectInstances["buttonClose"].instance.option("disabled", true);

            let valueToSubmit = dataSourceValue;
            if (!LDH.IsObjectNull(valueToSubmit._value) && !LDH.IsValueEmpty(valueToSubmit._value)) {
                valueToSubmit = valueToSubmit._value;
            }

            LeopardAjaxHelper.GridViewCRUD_ResetPassword(this.formDataToSubmit, valueToSubmit, function () {
                $(".leopard-textchanged-indicator").remove();
                $(".form-editor-loading-icon .loading-icon").hide();
                that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                that.uiObjectInstances["buttonClose"].instance.option("disabled", false);

                that.cancelButtonOnClick();
                LRH.ShowToast("The password has been successfully updated for this user.", "success", 5000);
            }, function (ex) {
                $(".form-editor-loading-icon .loading-icon").hide();
                that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                that.uiObjectInstances["buttonClose"].instance.option("disabled", false);

                if (JSON.stringify(ex).toLowerCase().indexOf("failed to set user password")) {
                    LRH.ShowToast("Unable to change your password. Your password must meet the complexity requirements policy.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to reset the password for this user. " +
                        JSON.stringify(ex.message), "error", 5000);
                }
            });
        }
    };

    convertDataSourceWithCaseSensitive = (definition, formDataToSubmit) => {
        let formDataKeys = Object.keys(formDataToSubmit);
        for (let i = 0; i < formDataKeys.length; i++) {
            for (let j = 0; j < definition.columnDefinition.length; j++) {
                if (definition.columnDefinition[j].columnName.toString().toLowerCase() ===
                    formDataKeys[i].toString().toLowerCase()) {
                    let dataKey = formDataKeys[i];
                    let dataValue = formDataToSubmit[formDataKeys[i]];
                    let settingsChanged = false;

                    if (!LDH.IsObjectNull(definition.columnDefinition[j].dataKeyName) &&
                        !LDH.IsValueEmpty(definition.columnDefinition[j].dataKeyName)) {
                        dataKey = definition.columnDefinition[j].dataKeyName;
                        settingsChanged = true;
                    } else {
                        if (!LDH.IsObjectNull(definition.columnDefinition[j].dataKeyFormat) &&
                            !LDH.IsValueEmpty(definition.columnDefinition[j].dataKeyFormat) &&
                            definition.columnDefinition[j].dataKeyFormat === "uppercase") {
                            dataKey = dataKey.toString().toUpperCase();
                            settingsChanged = true;
                        } else if (!LDH.IsObjectNull(definition.columnDefinition[j].dataKeyFormat) &&
                            !LDH.IsValueEmpty(definition.columnDefinition[j].dataKeyFormat) &&
                            definition.columnDefinition[j].dataKeyFormat === "lowercase") {
                            dataKey = dataKey.toString().toLowerCase();
                            settingsChanged = true;
                        }
                        if (!LDH.IsObjectNull(definition.columnDefinition[j].dataValueFormat) &&
                            !LDH.IsValueEmpty(definition.columnDefinition[j].dataValueFormat) &&
                            definition.columnDefinition[j].dataValueFormat === "uppercase" &&
                            !LDH.IsValueEmpty(dataValue)) {
                            dataValue = dataValue.toString().toUpperCase();
                            settingsChanged = true;
                        } else if (!LDH.IsObjectNull(definition.columnDefinition[j].dataValueFormat) &&
                            !LDH.IsValueEmpty(definition.columnDefinition[j].dataValueFormat) &&
                            definition.columnDefinition[j].dataValueFormat === "lowercase" &&
                            !LDH.IsValueEmpty(dataValue)) {
                            dataValue = dataValue.toString().toLowerCase();
                            settingsChanged = true;
                        }
                    }
                    if (settingsChanged === true) {
                        if (!LDH.IsObjectNull(formDataToSubmit[formDataKeys[i]])) {
                            delete formDataToSubmit[formDataKeys[i]];
                        }
                        formDataToSubmit[dataKey] = dataValue;
                    }
                }
            }
        }
        return formDataToSubmit;
    };

    addUIObjectInstance = (data) => {
        this.uiObjectInstances[data.name] = data.instance;
    };

    setDefaultValueToInputbox = (e, thisComp, item, customDefaultValue) => {
        let defaultValue = item.defaultValue;
        if (typeof customDefaultValue !== "undefined" && customDefaultValue !== "no-value") {
            defaultValue = customDefaultValue;
        }
        let that = thisComp;
        const v = e.component.option("value");

        if (that.callerInfo.columnType === "add-row") {
            if (!LDH.IsObjectNull(defaultValue) && !LDH.IsValueEmpty(defaultValue)) {
                e.component.option("value", defaultValue);
            } else {
                e.component.option("value", "");
            }
        } else {
            if ((v === null || v === "") && !LDH.IsObjectNull(defaultValue) &&
                !LDH.IsValueEmpty(defaultValue)) {
                e.component.option("value", defaultValue);
            }
        }
    };

    generateInputFields = () => {
        let that = this;
        if (!LDH.IsObjectNull(this.state.inputFields) && this.state.inputFields.length > 0) {
            return this.state.inputFields.map(function (item, i) {
                if (item.isHidden === true) return null;

                let fieldCustomKey = "";
                if (!LDH.IsObjectNull(item.fieldCustomKey) && !LDH.IsValueEmpty(item.fieldCustomKey)) {
                    fieldCustomKey = "_" + item.fieldCustomKey;
                }

                if (!LDH.IsObjectNull(item.isInvisible) && item.isInvisible === true) {
                    return (
                        <SimpleItem dataField={item.fieldName} editorType={"dxTextBox"} visible={false} editorOptions={{
                            onValueChanged: (e) => that.inputFieldValueOnChanged({
                                e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                controlId: "inputField_" + item.fieldName + fieldCustomKey
                            }),
                            onInitialized: function (e) {
                                that.setDefaultValueToInputbox(e, that, item, "no-value");
                            }
                        }} key={"builtInInputField_Control_" + i}>
                        </SimpleItem>
                    )
                }

                if (!LDH.IsObjectNull(item.refDataType) && !LDH.IsObjectNull(item.refDataOwnerId) &&
                    !LDH.IsObjectNull(item.refDataFileName) && !LDH.IsObjectNull(item.refDataFileVersion) &&
                    !LDH.IsObjectNull(item.refDataProperty)) {
                    let controlId = "selectbox_" + item.fieldName + "_" + item.refDataProperty;

                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxSelectBox"}
                                    editorOptions={{
                                        readOnly: item.isDisabled,
                                        displayExpr: "text",
                                        valueExpr: "value",
                                        searchEnabled: false,
                                        showDataBeforeSearch: false,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "inputField_" + item.fieldName + fieldCustomKey
                                        }),
                                        showDropDownButton: true,
                                        dataSource: that.state.dataSources[controlId],
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, "no-value");
                                        }
                                    }} key={"builtInInputField_Control_" + i} label={{text: item.caption}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "predefined", item.fieldName, item)
                            }
                        </SimpleItem>);
                } else if (!LDH.IsObjectNull(item.allowAutoComplete) && item.allowAutoComplete === true) {
                    let domainUrl = LDH.APIEndpointAdapter();
                    let url = window.Global_PopupCustomColumnData.getDataFromUrl;
                    let hasAutoCompleteDataSource = false;

                    if (!LDH.IsObjectNull(url) && !LDH.IsValueEmpty(url)) {
                        url = url.replace("?tableName=", "");
                    }

                    if (!LDH.IsObjectNull(item.autoCompleteDataSource) && !LDH.IsValueEmpty(item.autoCompleteDataSource)) {
                        url = item.autoCompleteDataSource;
                        hasAutoCompleteDataSource = true;
                    }

                    let autoCompleteDataSourceKeyName = "Unknown";
                    if (!LDH.IsObjectNull(item.autoCompleteDataSourceKeyName) &&
                        !LDH.IsValueEmpty(item.autoCompleteDataSourceKeyName)) {
                        autoCompleteDataSourceKeyName = item.autoCompleteDataSourceKeyName;
                    }
                    let definition = window.Global_PopupCustomColumnData.definition;
                    let dataSource = LRH.InitCustomStoreForAutoComplete(
                        domainUrl, url, item.autoCompleteOperation, item.fieldName, definition, function () {
                            $(".form-editor-loading-icon .loading-icon").show();
                            if (!LDH.IsObjectNull(that.uiObjectInstances["buttonApply"]) &&
                                !LDH.IsObjectNull(that.uiObjectInstances["buttonApply"].instance)) {
                                that.uiObjectInstances["buttonApply"].instance.option("disabled", true);
                            }
                            if (!LDH.IsObjectNull(that.uiObjectInstances["buttonCancel"]) &&
                                !LDH.IsObjectNull(that.uiObjectInstances["buttonCancel"].instance)) {
                                that.uiObjectInstances["buttonCancel"].instance.option("disabled", true);
                            }
                            if (!LDH.IsObjectNull(that.uiObjectInstances["buttonClose"]) &&
                                !LDH.IsObjectNull(that.uiObjectInstances["buttonClose"].instance)) {
                                that.uiObjectInstances["buttonClose"].instance.option("disabled", true);
                            }
                        }, function () {
                            $(".form-editor-loading-icon .loading-icon").hide();
                            if (!LDH.IsObjectNull(that.uiObjectInstances["buttonApply"]) &&
                                !LDH.IsObjectNull(that.uiObjectInstances["buttonApply"].instance)) {
                                that.uiObjectInstances["buttonApply"].instance.option("disabled", false);
                            }
                            if (!LDH.IsObjectNull(that.uiObjectInstances["buttonCancel"]) &&
                                !LDH.IsObjectNull(that.uiObjectInstances["buttonCancel"].instance)) {
                                that.uiObjectInstances["buttonCancel"].instance.option("disabled", false);
                            }
                            if (!LDH.IsObjectNull(that.uiObjectInstances["buttonClose"]) &&
                                !LDH.IsObjectNull(that.uiObjectInstances["buttonClose"].instance)) {
                                that.uiObjectInstances["buttonClose"].instance.option("disabled", false);
                            }
                        }, hasAutoCompleteDataSource, autoCompleteDataSourceKeyName, null, "");

                    let displayExpr = item.fieldName;
                    let valueExpr = item.fieldName;
                    if (!LDH.IsObjectNull(item.autoCompleteDataSource) && !LDH.IsValueEmpty(item.autoCompleteDataSource)) {
                        if (item.autoCompleteDataSource.indexOf("{") > -1 && item.autoCompleteDataSource.indexOf("}") > -1) {
                            let dataSourceName = item.autoCompleteDataSource.toString().replace("{", "");
                            dataSourceName = dataSourceName.replace("}", "");
                            dataSource = window[dataSourceName];
                            displayExpr = "text";
                            valueExpr = "value";
                        }
                    }

                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxSelectBox"}
                                    editorOptions={{
                                        readOnly: item.isDisabled,
                                        displayExpr: displayExpr,
                                        valueExpr: valueExpr,
                                        searchEnabled: true,
                                        searchMode: "contains",
                                        searchTimeout: 1000,
                                        minSearchLength: 0,
                                        showDataBeforeSearch: false,
                                        noDataText: "Enter value to search...",
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "inputField_" + item.fieldName + fieldCustomKey
                                        }),
                                        showDropDownButton: true,
                                        dataSource: dataSource,
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, "no-value");
                                        }
                                    }}
                                    key={"builtInInputField_Control_" + i} label={{text: item.caption}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "predefined", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                } else if (item.fieldType === "number") {
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxNumberBox"}
                                    editorOptions={{
                                        readOnly: item.isDisabled, showSpinButtons: true,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "inputField_" + item.fieldName + fieldCustomKey
                                        }),
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, "no-value");
                                        }
                                    }} key={"builtInInputField_Control_" + i} label={{text: item.caption}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "predefined", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                } else if (item.fieldType === "date") {
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxDateBox"}
                                    editorOptions={{
                                        readOnly: item.isDisabled, type: item.fieldType,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "inputField_" + item.fieldName + fieldCustomKey
                                        })
                                    }} key={"builtInInputField_Control_" + i} label={{text: item.caption}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "predefined", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                } else if (item.fieldType === "datetime") {
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxDateBox"}
                                    editorOptions={{
                                        readOnly: item.isDisabled, type: item.fieldType,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "inputField_" + item.fieldName + fieldCustomKey
                                        }),
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, "no-value");
                                        }
                                    }} key={"builtInInputField_Control_" + i} label={{text: item.caption}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "predefined", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                } else if (item.fieldType === "boolean") {
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxSwitch"}
                                    editorOptions={{
                                        readOnly: item.isDisabled, width: 70, switchedOffText: "NO",
                                        switchedOnText: "YES", onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "inputField_" + item.fieldName + fieldCustomKey
                                        }),
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, "no-value");
                                        }
                                    }} key={"builtInInputField_Control_" + i} label={{text: item.caption}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "predefined", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                } else {
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxTextBox"}
                                    editorOptions={{
                                        readOnly: item.isDisabled,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "inputField_" + item.fieldName + fieldCustomKey
                                        }),
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, "no-value");
                                        }
                                    }} key={"builtInInputField_Control_" + i} label={{text: item.caption}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "predefined", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                }
            });
        }
        return null;
    };

    generateCustomFields = () => {
        let that = this;
        if (!LDH.IsObjectNull(this.state.customFields) && this.state.customFields.length > 0) {
            return this.state.customFields.map(function (item, i) {
                let defaultValue = item.defaultValue;
                if (LDH.IsObjectNull(item.defaultValue) || LDH.IsValueEmpty(item.defaultValue)) {
                    defaultValue = "";
                }

                if (!LDH.IsObjectNull(that.state.formData)) {
                    let foundData = false;
                    let rowDataKeys = Object.keys(that.state.formData);
                    for (let v = 0; v < rowDataKeys.length; v++) {
                        let dataToSearch = "{" + rowDataKeys[v] + "}"
                        if (defaultValue.indexOf(dataToSearch) > -1) {
                            let cellValue = that.state.formData[rowDataKeys[v]];
                            let newPath = LDH.ReplaceAll(defaultValue, dataToSearch, cellValue);
                            defaultValue = newPath;
                            foundData = true;
                        }
                    }
                    if (foundData === false) defaultValue = "";
                }

                if (!LDH.IsObjectNull(item.defaultValue) && !LDH.IsValueEmpty(item.defaultValue)) {
                    item.defaultValue = LDH.FilterMacro(item.defaultValue);
                    item.defaultValue = LDH.ConvertArrayMacroToString(item.defaultValue, that.state.formData);
                    defaultValue = item.defaultValue;
                }

                // if (!LDH.IsObjectNull(item.defaultValue) && !LDH.IsValueEmpty(item.defaultValue) &&
                //     that.callerInfo.columnType === "add-row") {
                //     defaultValue = LDH.FilterMacro(item.defaultValue);
                // }

                if (LDH.IsValueEmpty(defaultValue) === false) {
                    that.formDataToSubmit[item.fieldName] = defaultValue;
                }

                let fieldCustomKey = "";
                if (!LDH.IsObjectNull(item.fieldCustomKey) && !LDH.IsValueEmpty(item.fieldCustomKey)) {
                    fieldCustomKey = "_" + item.fieldCustomKey;
                }

                if (!LDH.IsObjectNull(item.invisible) && item.invisible) {
                    return (
                        <SimpleItem dataField={item.fieldName} editorType={"dxTextBox"}
                                    key={"customInputField_Control_" + i} visible={false}
                                    editorOptions={{
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "customField_" + item.fieldName + fieldCustomKey
                                        }),
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, defaultValue);
                                        }
                                    }}>
                        </SimpleItem>
                    )
                }

                if (item.fieldType === "number") {
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxNumberBox"}
                                    editorOptions={{
                                        showSpinButtons: true, onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "customField_" + item.fieldName + fieldCustomKey,
                                            readOnly: LDH.IsObjectNull(item.readOnly) ? false : item.readOnly
                                        }),
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, defaultValue);
                                        }
                                    }} key={"customInputField_Control_" + i} label={{text: item.displayName}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "custom", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                } else if (item.fieldType === "date") {
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxDateBox"}
                                    editorOptions={{
                                        type: item.fieldType, onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "customField_" + item.fieldName + fieldCustomKey,
                                            readOnly: LDH.IsObjectNull(item.readOnly) ? false : item.readOnly,
                                        }),
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, defaultValue);
                                        }
                                    }} key={"customInputField_Control_" + i} label={{text: item.displayName}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "custom", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                } else if (item.fieldType === "datetime") {
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxDateBox"}
                                    editorOptions={{
                                        type: item.fieldType, onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "customField_" + item.fieldName + fieldCustomKey,
                                            readOnly: LDH.IsObjectNull(item.readOnly) ? false : item.readOnly,
                                        }),
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, defaultValue);
                                        }
                                    }} key={"customInputField_Control_" + i} label={{text: item.displayName}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "custom", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                } else if (item.fieldType === "boolean") {
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxSwitch"}
                                    editorOptions={{
                                        width: 70, switchedOffText: "NO", switchedOnText: "YES",
                                        readOnly: LDH.IsObjectNull(item.readOnly) ? false : item.readOnly,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "customField_" + item.fieldName + fieldCustomKey
                                        }),
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, defaultValue);
                                        }
                                    }} key={"customInputField_Control_" + i} label={{text: item.displayName}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "custom", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                } else if (item.fieldType === "dropdown") {
                    let datasource = item.values;
                    if (typeof datasource === "undefined" || datasource === null) datasource = [];
                    let valueExpr = "id";
                    let displayExpr = "name";
                    if (typeof item.dataSource !== "undefined" && item.dataSource === "reference-data") {
                        datasource = that.state.dataSources["selectbox_" + item.fieldName + "_" + item.refDataProperty];
                        if (typeof datasource === "undefined" || datasource === null) datasource = [];
                        valueExpr = "value";
                        displayExpr = "text";
                    } else if (!LDH.IsObjectNull(item.query_odata) && !LDH.IsValueEmpty(item.query_odata)) {
                        datasource = that.state.dataSources["selectbox_" + item.fieldName + "_OdataDS"];
                        if (typeof datasource === "undefined" || datasource === null) datasource = [];
                        valueExpr = "value";
                        displayExpr = "text";
                    }
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxSelectBox"}
                                    editorOptions={{
                                        readOnly: item.readOnly,
                                        displayExpr: displayExpr,
                                        valueExpr: valueExpr,
                                        searchEnabled: true,
                                        searchMode: "contains",
                                        searchTimeout: 1000,
                                        minSearchLength: 0,
                                        showDataBeforeSearch: false,
                                        noDataText: "Enter value to search...",
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "customField_" + item.fieldName + fieldCustomKey
                                        }),
                                        showDropDownButton: true,
                                        dataSource: datasource,
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, defaultValue);
                                        }
                                    }} key={"customInputField_Control_" + i} label={{text: item.displayName}}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "custom", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                } else {
                    return (
                        <SimpleItem visible={item.visibleProperty} dataField={item.fieldName} editorType={"dxTextBox"}
                                    key={"customInputField_Control_" + i}
                                    helpText={item.helpText}
                                    label={{text: item.displayName}}
                                    editorOptions={{
                                        readOnly: LDH.IsObjectNull(item.readOnly) ? false : item.readOnly,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e, fieldName: item.fieldName, fieldValue: e.value, itemObj: item,
                                            controlId: "customField_" + item.fieldName + fieldCustomKey
                                        }),
                                        onInitialized: function (e) {
                                            that.setDefaultValueToInputbox(e, that, item, defaultValue);
                                        }
                                    }}>
                            {
                                (LDH.IsObjectNull(item.validationRules) || LDH.IsValueEmpty(item.validationRules)) ? null :
                                    that.generateValidationRules(item.validationRules, "custom", item.fieldName, item)
                            }
                        </SimpleItem>
                    )
                }
            });
        }
        return null;
    };

    render() {
        if (this.disposingAllInstances) return null;

        return (
            <Popup className={'leopard-popup-window edit-form'} visible={this.state.popupVisible}
                   dragEnabled={true} hideOnOutsideClick={false} onHiding={this.popupOnHide}
                   onShowing={this.popupOnShowing} showTitle={true} title={this.popupTitle}
                   onShown={this.popupOnShown} resizeEnabled={true} {...this.props.sizeAttributes}
                   ref={this.props.popupEditFormInstance}>
                <div className={"leopard-popup-window-editorpanel"}>
                    <ScrollView showScrollbar={"onHover"} scrollByThumb={true} scrollByContent={false}
                                ref={(e) => this.addUIObjectInstance({name: "scrollview", instance: e})}>
                        <Form colCount={this.props.popupFormEditorRowCount} formData={this.state.formData}
                              labelLocation={"top"}
                              colCountByScreen={{
                                  md: this.props.popupFormEditorRowCount,
                                  sm: this.props.popupFormEditorRowCount, lg: this.props.popupFormEditorRowCount,
                                  xs: 1
                              }}
                              ref={(e) => this.addUIObjectInstance({name: "form", instance: e})}
                              validationGroup={"formeditor-validation-group"}
                              style={{paddingRight: "10px", paddingLeft: "10px"}}>
                            {this.generateInputFields()}
                            {this.generateCustomFields()}
                        </Form>
                    </ScrollView>
                </div>
                <div className={"leopard-popup-window-accesspanel"}>
                    <span className={"form-editor-loading-icon leopard-loading-icon"}
                          style={{position: "absolute", right: "250px"}}>
                          <i className="loading-icon fas fa-spinner fa-pulse" style={{
                              fontSize: "25px", display: "none"
                          }}></i>
                    </span>
                    <span style={{paddingRight: "10px"}}>
                        <Button className="leopard-button" text={'Cancel'}
                                ref={(e) => this.addUIObjectInstance({name: "buttonCancel", instance: e})}
                                onClick={(e) => this.cancelButtonOnClick(e)}/>
                    </span>
                    <span style={{paddingRight: "10px"}}>
                        <Button className="leopard-button" text={'Close'} onClick={this.cancelButtonOnClick}
                                validationGroup={"formeditor-validation-group"}
                                ref={(e) => this.addUIObjectInstance({name: "buttonClose", instance: e})}/>
                    </span>
                    <span style={{paddingRight: "10px"}}>
                        <Button className="leopard-button" text={'Apply'} onClick={this.applyButtonOnClick}
                                validationGroup={"formeditor-validation-group"}
                                ref={(e) => this.addUIObjectInstance({name: "buttonApply", instance: e})}/>
                    </span>
                </div>
            </Popup>
        )
    }
}

export default LeopardFormEditor;
